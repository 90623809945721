import React from "react"
import Page from "../components/page"
import pageStyles from "../components/page.module.css"
import styles from "./cookies.module.css"

export default function Cookies({ location }) {
  return (
    <Page title={"Cookies – PhotoTailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <div className={styles.main}>

            <p>Photo Tailors Limited (“us”, “we”, or “our”) uses cookies on the https://www.phototailors.com website
              (the “Service”). By using the Service, you consent to the use of cookies.</p>
            <p>Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with
              may use cookies on the Service, your choices regarding cookies and further information about cookies.</p>
            <p>&nbsp;</p>
            <h3>What are Cookies</h3>
            <br/>
            <p>Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored
              in your web browser and allows the Service or a third-party to recognize you and make your next visit
              easier and the Service more useful to you.</p>
            <p>Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your personal computer or
              mobile device when you go offline, while session cookies are deleted as soon as you close your web
              browser.</p>
            <p>&nbsp;</p>
            <h3>How Photo Tailors Limited uses cookies</h3>
            <br/>
            <p>When you use and access the Service, we may place a number of cookies files in your web browser.</p>
            <p>We use cookies for the following purposes:</p>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li>To enable certain functions of the Service</li>
                  <li>To provide analytics</li>
                  <li>To store your preferences</li>
                  <li>To enable advertisements delivery, including behavioral advertising</li>
                </ul>
              </li>
            </ul>
            <br/>
            <p>We use both session and persistent cookies on the Service and we use different types of cookies to run
              the Service:</p>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li>Essential cookies. We may use essential cookies to authentic users and prevent fraudulent use of
                    user accounts.
                  </li>
                  <li>Preferences cookies. We may use preferences cookies to remember information that changes the way
                    the Service behaves or looks, such as the “remember me” functionality of a registered user or a
                    user’s language preference.
                  </li>
                  <li>Analytics cookies. We may use analytics cookies to track information how the Service is used so
                    that we can make improvements. We may also use analytics cookies to test new advertisements, pages,
                    features or new functionality of the Service to see how our users react to them.
                  </li>
                  <li>Advertising cookies. These type of cookies are used to deliver advertisements on and through the
                    Service and track the performance of these advertisements. These cookies may also be used to enable
                    third-party advertising networks to deliver ads that may be relevant to you based upon your
                    activities or interests.
                  </li>
                </ul>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h3>Third-party Cookies</h3>
            <br/>
            <p>In addition to our own cookies, we may also use various third-parties cookies to report usage statistics
              of the Service, deliver advertisements on and through the Service, and so on.</p>
            <p>&nbsp;</p>
            <h3>What are your choices regarding cookies</h3>
            <br/>
            <p>If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit
              the help pages of your web browser.</p>
            <p>Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use
              all of the features we offer, you may not be able to store your preferences, and some of our pages might
              not display properly.</p>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li>For the Chrome web browser, please visit&nbsp;<a
                    href="https://support.google.com/accounts/answer/32050" target="_blank" rel="noopener noreferrer">this
                    page from Google</a>.
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li>For the Internet Explorer web browser, please visit&nbsp;<a
                    href="http://support.microsoft.com/kb/278835" target="_blank" rel="noopener noreferrer">this page
                    from Microsoft</a>.
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li>For the Firefox web browser, please visit&nbsp;<a
                    href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                    target="_blank" rel="noopener noreferrer">this page from Mozilla</a>.
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li>For the Safari web browser, please visit&nbsp;<a
                    href="https://support.apple.com/kb/PH21411?locale=en_US" target="_blank" rel="noopener noreferrer">this
                    page from Apple</a>.
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>For any other web browser, please visit your web browser’s official web pages where can you find more
                information.
              </li>
            </ul>
            <p>&nbsp;</p>
            <h3>Where can you find more information about cookies</h3>
            <br/>
            <p>We may use your Personal Information to contact you with newsletters, marketing or promotional materials
              and other information that may be of interest to you. You may opt out of receiving any, or all, of these
              communications from us by following the unsubscribe link or instructions provided in any email we send or
              by <a href="https://www.phototailors.com/contact-us">contacting us</a>.</p>
            <p>You can learn more about cookies and the following third-party websites:</p>
            <ul>
              <li className={styles.nolist}>
                <ul>
                  <li><a href="http://www.allaboutcookies.org/" target="_blank"
                         rel="noopener noreferrer">AllAboutCookies</a></li>
                  <li><a href="http://www.networkadvertising.org/" target="_blank" rel="noopener noreferrer">Network
                    Advertising Initiative</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Page>


  )
}
